// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alumni-js": () => import("./../../../src/pages/alumni.js" /* webpackChunkName: "component---src-pages-alumni-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-core-team-js": () => import("./../../../src/pages/core-team.js" /* webpackChunkName: "component---src-pages-core-team-js" */),
  "component---src-pages-current-students-js": () => import("./../../../src/pages/current-students.js" /* webpackChunkName: "component---src-pages-current-students-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-adrift-lab-js": () => import("./../../../src/pages/join-adrift-lab.js" /* webpackChunkName: "component---src-pages-join-adrift-lab-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-templates-news-post-template-js": () => import("./../../../src/templates/news-post-template.js" /* webpackChunkName: "component---src-templates-news-post-template-js" */),
  "component---src-templates-publications-post-template-js": () => import("./../../../src/templates/publications-post-template.js" /* webpackChunkName: "component---src-templates-publications-post-template-js" */),
  "component---src-templates-team-profile-template-js": () => import("./../../../src/templates/team-profile-template.js" /* webpackChunkName: "component---src-templates-team-profile-template-js" */)
}

